<template>
  <div
    v-if="hasData"
    class="scoreboard-details-basketball"
  >
    <div class="scoreboard-details-basketball__content">
      <div class="scoreboard-details-basketball__row scoreboard-details-basketball__row--header">
        <div class="scoreboard-details-basketball__cell">
          Name
        </div>
        <div
          v-for="team in teams"
          :key="team.field"
          class="scoreboard-details-basketball__cell"
        >
          {{ team.label }}
        </div>
      </div>
      <div
        v-for="(label, type) in configuration?.labels"
        :key="type"
        class="scoreboard-details-basketball__row scoreboard-details-basketball__row--data"
      >
        <div class="scoreboard-details-basketball__cell">
          {{ label }}
        </div>
        <div
          v-for="team in teams"
          :key="team.field"
          class="scoreboard-details-basketball__cell"
        >
          {{ formatValue(type, getData(type)[team.field]) }}
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="scoreboard-details-basketball"
  >
    <div class="scoreboard-details-basketball__message">
      No data available.
    </div>
  </div>
</template>

<script>
import { orderBy, isFinite, isFunction } from 'lodash';
import { computed } from 'vue';
import { useStore } from 'vuex';
import Basketball from '@/services/helpers/basketball';
import { createDataSet, createStatistics } from '@/services/helpers/statistics';

const defaultFormatter = (value) => (isFinite(value) ? value : '-');

export default {
  props: {
    periodCode: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const event = computed(() => store.getters.event);
    const isUsaView = computed(() => event.value.isUSAView);
    const configuration = computed(() => Basketball.createStatisticsConfiguration(event.value));
    const dataSet = computed(() => configuration.value && createDataSet(event.value, configuration.value));
    const statistics = computed(() => dataSet.value && createStatistics(dataSet.value));
    const selectedStatistics = computed(() => statistics.value?.[props.periodCode]);
    const teams = computed(() => orderBy([
      {
        label: event.value.away.name,
        field: 'awayValue',
        order: isUsaView.value ? 0 : 1,
      },
      {
        label: event.value.home.name,
        field: 'homeValue',
        order: isUsaView.value ? 1 : 0,
      },
    ], 'order'));
    const hasData = computed(() => !!configuration.value?.labels && selectedStatistics.value);
    const getData = (type) => selectedStatistics.value[type];
    const formatValue = (type, value) => {
      const typeFormatter = configuration.value.formatters[type];
      if (isFunction(typeFormatter)) return typeFormatter(value);
      return defaultFormatter(value);
    };

    return {
      event,
      teams,
      statistics,
      configuration,
      hasData,
      getData,
      formatValue,
    };
  },
};
</script>

<style lang="scss" scoped>
.scoreboard-details-basketball {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.scoreboard-details-basketball__content {
  display: table;
  border-collapse: collapse;
  position: relative;
  width: 100%;
}

.scoreboard-details-basketball__row {
  display: table-row;
  width: 100%;
  height: 33px;

  &.scoreboard-details-basketball__row--header {
    background-color: #fafafa;
    color: #6D6D6D;

    .scoreboard-details-basketball__cell {
      position: sticky;
      top: 0;
      z-index: 1;

      &:first-of-type {
        left: 0;
        z-index: 2;
      }
    }
  }

  &.scoreboard-details-basketball__row--data {
    background-color: #fff;
    color: #191414;
    border-bottom: 1px solid #F0F0F0;

    .scoreboard-details-basketball__cell:first-of-type {
      position: sticky;
      left: 0;
    }
  }
}

.scoreboard-details-basketball__cell {
  background-color: inherit;
  color: inherit;
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  min-width: 50px;
  padding: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:not(:first-of-type) {
    text-align: right;
  }
}

.scoreboard-details-basketball__message {
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #A9A9A9;
  text-align: center;
  width: 100%;
}
</style>
