<template>
  <div
    class="scoreboard-player-statistics-team-player-data"
    v-for="(stat, index) in playerFootballData"
    :key="index"
  >
    <div class="scoreboard-player-statistics-team-player-data-label">
      {{ stat.label }}
    </div>
    <div class="scoreboard-player-statistics-team-player-data-value">
      {{ player.playerStats[stat.value] }}
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    player: {
      type: Object,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const playerToggled = ref(true);
    const playerFootballData = [
      {
        label: 'Pass Attempts',
        value: 'passAttempts',
      },
      {
        label: 'Pass Completions',
        value: 'passCompletions',
      },
      {
        label: 'Receptions',
        value: 'receptions',
      },
      {
        label: 'Receiving Yards',
        value: 'receivingYards',
      },
      {
        label: 'Rushing Attempts',
        value: 'rushingAttempts',
      },
      {
        label: 'Rushing Yards',
        value: 'rushingYards',
      },
      {
        label: 'Passing Yards',
        value: 'passingYards',
      },
      {
        label: 'Passing Touchdowns',
        value: 'passingTouchdowns',
      },
      {
        label: 'Touchdowns',
        value: 'touchdowns',
      },
      {
        label: 'Yards on Touchdowns',
        value: 'touchdownYards',
      },
      {
        label: 'Interceptions',
        value: 'interceptions',
      },
    ];

    return {
      playerToggled,
      playerFootballData,
    };
  },
};
</script>

<style lang="scss">
    .scoreboard-player-statistics-team-player-data {
      display: flex;
      width: 100%;
      height: 33px;
      align-items: center;
      justify-content: space-between;
      padding: 0 8px;
      border-bottom: 1px solid #F0F0F0;

      &:nth-child(odd) {
        background-color: #FAFAFA;
      }
    }
</style>
