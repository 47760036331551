<template>
  <div class="scoreboard-statistics-basketball-details">
    <div class="scoreboard-statistics-basketball-details__information">
      <div
        v-for="header in informationHeaders"
        :key="header.id"
        class="scoreboard-statistics-basketball-details__information-item"
      >
        <div class="scoreboard-statistics-basketball-details__information-item-label">
          {{ header.label }}
        </div>
        <div
          v-if="header.type === 'boolean'"
          class="scoreboard-statistics-basketball-details__information-item-value"
        >
          <div class="scoreboard-statistics-basketball-details__statistics-cell-icon">
            <Icon
              v-if="informationData[header.id]"
              name="boolean-true"
            />
            <Icon
              v-else
              name="boolean-false"
            />
          </div>
        </div>
        <div
          v-else
          class="scoreboard-statistics-basketball-details__information-item-value"
        >
          {{ formatValue(header.type, informationData[header.id], event) }}
        </div>
      </div>
    </div>
    <div class="scoreboard-statistics-basketball-details__statistics">
      <div class="scoreboard-statistics-basketball-details__statistics-head">
        <div class="scoreboard-statistics-basketball-details__statistics-row">
          <div class="scoreboard-statistics-basketball-details__statistics-cell">
            Quarter
          </div>
          <div
            v-for="header in statisticsHeaders"
            :key="header.id"
            class="scoreboard-statistics-basketball-details__statistics-cell"
          >
            {{ header.label }}
          </div>
        </div>
      </div>
      <div class="scoreboard-statistics-basketball-details__statistics-body">
        <div
          v-for="period in periods"
          :key="period"
          class="scoreboard-statistics-basketball-details__statistics-row"
        >
          <div class="scoreboard-statistics-basketball-details__statistics-cell">
            {{ formatValue('period', period, event) }}
          </div>
          <div
            v-for="header in statisticsHeaders"
            :key="header.id"
            class="scoreboard-statistics-basketball-details__statistics-cell"
          >
            {{ formatValue(header.type, statisticsData[period][header.id], event) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { keys, isNaN } from 'lodash';
import { computed } from 'vue';
import basketballHelpers from '@/services/helpers/basketball';
import { formatMinutesAndSeconds } from '@/utils/number';
import {
  createInformationHeaders,
  createInformationData,
  createStatisticsHeaders,
  createStatisticsData,
} from '@/services/helpers/basketball-player-stats';
import Icon from '@/components/common/Icon';

const formatValue = (type, value, event) => {
  if (isNaN(value)) return '-';
  if (type === 'timeInMinutes') return formatMinutesAndSeconds(value * 60);
  if (type === 'time') return formatMinutesAndSeconds(value);
  if (type === 'period') {
    if (value === 'TOTAL') return 'Total';
    if (value === 'PRE_GAME') return 'Pregame';
    return basketballHelpers.formatPeriodLabel({ period: value, competitionType: event.competitionType });
  }
  return value;
};

export default {
  components: {
    Icon,
  },
  props: {
    player: {
      type: Object,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const informationHeaders = computed(() => createInformationHeaders());
    const informationData = computed(() => createInformationData(props.player, props.event));

    const periods = computed(() => [...basketballHelpers.sortPeriods(keys(props.player.playerStats.periodStats)), 'TOTAL']);
    const statisticsHeaders = computed(() => createStatisticsHeaders());
    const statisticsData = computed(() => createStatisticsData(periods.value, props.player));

    return {
      informationHeaders,
      informationData,
      periods,
      statisticsHeaders,
      statisticsData,
      formatValue,
    };
  },
};
</script>

<style lang="scss">
.scoreboard-statistics-basketball-details {
  .icon,
  .scoreboard-statistics-basketball-details__statistics-cell-icon {
    width: 16px;
    height: 16px;
  }

  .scoreboard-statistics-basketball-details__information {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .scoreboard-statistics-basketball-details__information-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: 33px;

    .scoreboard-statistics-basketball-details__information-item-label {
      color: #A9A9A9;
      font-family: Rubik;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }

    .scoreboard-statistics-basketball-details__information-item-value {
      color:#191414;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .scoreboard-statistics-basketball-details__statistics {
    display: flex;
    flex-direction: column;
    width: 100%;

    .scoreboard-statistics-basketball-details__statistics-row {
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .scoreboard-statistics-basketball-details__statistics-cell {
      padding: 8px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:not(:nth-child(1)) {
        text-align: right;
      }
    }

    .scoreboard-statistics-basketball-details__statistics-head,
    .scoreboard-statistics-basketball-details__statistics-body {
      width: 100%;
    }

    .scoreboard-statistics-basketball-details__statistics-head {
      .scoreboard-statistics-basketball-details__statistics-row {
        background: #FAFAFA;
        color: #6D6D6D;
      }
    }

    .scoreboard-statistics-basketball-details__statistics-body {
      .scoreboard-statistics-basketball-details__statistics-row {
        border-bottom: 1px solid #F0F0F0;

        &:nth-child(even) {
          background: #FAFAFA;
        }

        &:nth-last-child(2) {
          border-bottom: 1px solid #CDCDCD;
        }

        &:last-of-type {
          background-color: #fff;
          border-bottom: none;
          color: #191414;
          font-family: Rubik;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px; /* 114.286% */
        }
      }
    }
  }
}
</style>
