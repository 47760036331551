<template>
  <div class="scoreboard-details">
    <ScoreboardDetailsPeriods
      v-model:selected="selectedTab"
      :periods="periodTabs"
      :readonly="isFootball || isUltimate"
      :is-usa-view="isUsaView"
    />

    <div class="scoreboard-details__stats">
      <ScoreboardDetailsSoccer
        v-if="isSoccer"
        :period-code="selectedTab"
      />
      <ScoreboardDetailsBasketball
        v-else-if="isBasketball"
        :period-code="selectedTab"
      />
      <ScoreboardDetailsHockey
        v-else-if="isHockey"
        :period-code="selectedTab"
      />
      <ScoreboardDetailsBaseball
        v-else-if="isBaseball"
        :period-code="selectedTab"
      />
      <span
        v-else
        class="scoreboard-details__message"
      >
        No statistics available at this moment.
      </span>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import Event from '@/services/helpers/event';
import Soccer from '@/services/helpers/soccer';
import Basketball from '@/services/helpers/basketball';
import Football from '@/services/helpers/football';
import Ultimate from '@/services/helpers/ultimate';
import Hockey from '@/services/helpers/hockey';
import Baseball from '@/services/helpers/baseball';
import ScoreboardDetailsPeriods from './ScoreboardDetailsPeriods';
import ScoreboardDetailsSoccer from './ScoreboardDetailsSoccer';
import ScoreboardDetailsBasketball from './ScoreboardDetailsBasketball';
import ScoreboardDetailsHockey from './ScoreboardDetailsHockey';
import ScoreboardDetailsBaseball from './ScoreboardDetailsBaseball';
import sportIds from '@/services/helpers/sports';

const {
  BASKETBALL_ID,
  FOOTBALL_ID,
  SOCCER_ID,
  ULTIMATE_ID,
  HOCKEY_ID,
  BASEBALL_ID,
} = sportIds;

export default {
  components: {
    ScoreboardDetailsPeriods,
    ScoreboardDetailsSoccer,
    ScoreboardDetailsBasketball,
    ScoreboardDetailsHockey,
    ScoreboardDetailsBaseball,
  },
  setup() {
    const store = useStore();
    const event = computed(() => store.getters.event);
    const isUsaView = computed(() => event.value.isUSAView);
    const sportUtils = computed(() => {
      switch (event.value.sport.id) {
      case SOCCER_ID:
        return Soccer;
      case BASKETBALL_ID:
        return Basketball;
      case FOOTBALL_ID:
        return Football;
      case ULTIMATE_ID:
        return Ultimate;
      case HOCKEY_ID:
        return Hockey;
      case BASEBALL_ID:
        return Baseball;
      default:
        return null;
      }
    });
    const periodTabs = computed(() => sportUtils.value.getDetails(event.value, Event.isPrematch(event.value)));
    const selectedTab = ref(periodTabs.value[0].code);
    const isSportSupported = computed(() => !!sportUtils.value);
    const isSoccer = computed(() => event.value.sport.id === SOCCER_ID);
    const isBasketball = computed(() => event.value.sport.id === BASKETBALL_ID);
    const isFootball = computed(() => event.value.sport.id === FOOTBALL_ID);
    const isHockey = computed(() => event.value.sport.id === HOCKEY_ID);
    const isUltimate = computed(() => event.value.sport.id === ULTIMATE_ID);
    const isBaseball = computed(() => event.value.sport.id === BASEBALL_ID);

    return {
      isUsaView,
      periodTabs,
      selectedTab,
      isSportSupported,
      isSoccer,
      isBasketball,
      isFootball,
      isHockey,
      isUltimate,
      isBaseball,
    };
  },
};
</script>

<style lang="scss">
.scoreboard-details {
  flex: 1;
  width: 100%;
  margin: 16px 0 0;

  .scoreboard-details__message {
    font-size: 16px;
    color: #6D6D6D;
  }
}
</style>
