<template>
  <div
    ref="rootElement"
    class="region-select"
  >
    <button
      class="region-select__toggle"
      @click="toggleMenu"
    >
      <span>
        {{ toggleLabel }}
      </span>
      <Icon name="chevron-selector" />
    </button>
    <ul
      v-if="isMenuToggled"
      class="region-select__menu"
    >
      <li
        class="region-select__option"
        title="All"
        @click="updateValue('')"
      >
        <span class="region-select__option-label">
          All
        </span>
        <Icon
          v-if="!value"
          name="check"
        />
      </li>
      <li
        v-for="option in options"
        :key="option.id"
        class="region-select__option"
        :title="option.name"
        @click="updateValue(option.id)"
      >
        <span class="region-select__option-label">
          {{ option.name }}
        </span>
        <Icon
          v-if="value === option.id"
          name="check"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { find } from 'lodash';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  emits: {
    'update:value': {
      type: String,
    },
  },
  setup(props, { emit }) {
    const rootElement = ref(null);
    const isMenuToggled = ref(false);
    const toggleLabel = computed(() => find(props.options, { id: props.value })?.name || 'All');

    const toggleMenu = () => {
      isMenuToggled.value = !isMenuToggled.value;
    };
    const updateValue = (value) => {
      emit('update:value', value);
      isMenuToggled.value = false;
    };

    onClickOutside(rootElement, () => {
      isMenuToggled.value = false;
    });

    return {
      rootElement,
      isMenuToggled,
      toggleLabel,
      toggleMenu,
      updateValue,
    };
  },
};
</script>

<style lang="scss">
.region-select {
  display: flex;
  flex-direction: column;
  position: relative;
  user-select: none;

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    background-color: #fff;
    color: #191414;
    border: 1px solid #f0f0f0;
    padding: 8px;
    gap: 4px;
    border-radius: 4px;
    cursor: pointer;
    height: 32px;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icon {
      fill: #191414;
      flex-shrink: 0;
    }

    &:focus {
      box-shadow: 0px 0px 0px 2px #A2BFFF;
      outline: none;
    }
  }

  &__menu {
    position: absolute;
    top: calc(100% + 4px);
    background-color: #fff;
    color: #191414;
    box-shadow: 0 4px 8px rgba(0,0,0,.08);
    border-radius: 4px;
    margin-bottom: 4px;
    padding: 4px 0;
    border: 1px solid #f0f0f0;
    width: 100%;
    max-height: 210px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    z-index: 1000;
    overflow-y: auto;
  }

  &__option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    white-space: nowrap;
    cursor: pointer;
    height: 32px;

    &:hover {
      background-color: #fafafa;
    }

    &-label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .icon {
      width: 16px;
      height: 16px;
      padding: 2px;
      stroke: #000;
    }
  }
}
</style>
