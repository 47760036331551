<template>
  <DoubleBarChart
    title="Shots"
    :first-value="getPeriodValue('home.shotsOnTarget') + getPeriodValue('home.shotsOffTarget')"
    :second-value="getPeriodValue('away.shotsOnTarget') + getPeriodValue('away.shotsOffTarget')"
    :reverse="isUsaView"
  />
  <DoubleBarChart
    title="Shots off target"
    :first-value="getPeriodValue('home.shotsOffTarget')"
    :second-value="getPeriodValue('away.shotsOffTarget')"
    :reverse="isUsaView"
  />
  <DoubleBarChart
    title="Fouls"
    :first-value="getPeriodValue('home.fouls')"
    :second-value="getPeriodValue('away.fouls')"
    :reverse="isUsaView"
  />
  <DoubleBarChart
    title="Yellow cards"
    :first-value="getPeriodValue('home.yellowCards')"
    :second-value="getPeriodValue('away.yellowCards')"
    :reverse="isUsaView"
  />
  <DoubleBarChart
    title="Red cards"
    :first-value="getPeriodValue('home.redCards')"
    :second-value="getPeriodValue('away.redCards')"
    :reverse="isUsaView"
  />
</template>

<script>
import { filter, get } from 'lodash';
import { computed } from 'vue';
import { useStore } from 'vuex';
import DoubleBarChart from '@/components/common/DoubleBarChart';

export default {
  components: {
    DoubleBarChart,
  },
  props: {
    periodCode: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const event = computed(() => store.getters.event);
    const isUsaView = computed(() => event.value.isUSAView);
    const getPeriodValue = (key) => {
      const values = get(event.value, key, []);
      if (props.periodCode === 'TOTAL') return values.length || 0;
      return filter(get(event.value, key, []), { period: props.periodCode }).length || 0;
    };

    return {
      isUsaView,
      getPeriodValue,
    };
  },
};
</script>
