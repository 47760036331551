<template>
  <teleport to="body">
    <div
      v-if="state.loading"
      class="replay-manager"
    >
      <div
        class="replay-manager__dialog"
        @click.stop="() => {}"
      >
        <div class="replay-manager__loader">
          <Icon name="info" />
          Loading event replay...
        </div>
      </div>
    </div>
    <div
      v-else
      class="replay-manager"
    >
      <div
        class="replay-manager__dialog"
        @click.stop="() => {}"
        ref="replayManagerRef"
      >
        <div class="replay-manager__header">
          <div class="replay-manager__title">
            {{ title }}
          </div>
          <button
            class="replay-manager__close-button"
            @click="close"
            :disabled="state.processing"
          >
            <XIcon />
          </button>
          <div
            v-if="state.available"
            class="replay-manager__subtitle"
          >
            {{ subtitle }}
          </div>
        </div>
        <div class="replay-manager__content">
          <div
            v-if="!state.available"
            class="replay-manager__message"
          >
            Sorry, there is no replay available for this event.
          </div>
          <div
            v-else
          >
            <div
              v-if="eventHealth"
              class="replay-manager__replay-health"
            >
              <div class="replay-manager__replay-health-header">
                Replay quality:
                <div
                  class="replay-manager__replay-health-badge"
                  :class="getHealthStatus(eventHealth.feedSize).className"
                >
                  {{ getHealthStatus(eventHealth.feedSize).label }}
                </div>
                <div class="replay-manager__replay-providers">
                  <div
                    v-for="provider in Object.values(providers)"
                    :key="provider"
                    class="replay-manager__replay-provider"
                  >
                    {{ providerNames[provider] }}
                    <Tooltip
                      :text="getHealthStatus(eventHealth.eventFeeds[provider]?.feedSize).label"
                      bottom
                    >
                      <Icon
                        name="status"
                        :class="getHealthStatus(eventHealth.eventFeeds[provider]?.feedSize).className"
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            <ReplayManagerSchedule
              v-model:schedule="state.schedules"
              :disabled="state.processing"
            />
          </div>
        </div>
        <div
          v-if="!state.loading"
          class="replay-manager__footer"
        >
          <Button
            v-if="!state.available"
            variant="primary"
            @click="close"
          >
            Ok, close
          </Button>
          <Button
            v-else
            variant="primary"
            :loading="state.processing"
            @click="confirm"
          >
            Schedule replay(s)
          </Button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import {
  reactive, computed, onMounted, ref,
} from 'vue';
import { useStore } from 'vuex';
import { onClickOutside } from '@vueuse/core';
import { scheduleReplay, isReplayable, recordingInfo } from '@/services/api';
import { XIcon } from '@/components/svg';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';
import Button from '@/components/common/Button';
import ReplayManagerSchedule from './ReplayManagerSchedule';

const emitTypes = {
  CLOSE: 'close',
};

const feedStatus = {
  UNKNOWN: 'UNKNOWN',
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH',
};

const providers = {
  BETGENIUS: 'idm-betgenius',
  BETRADAR: 'idm-betradar',
  LSPORTS: 'idm-lsports',
};

const providerNames = {
  [providers.BETGENIUS]: 'BetGenius:',
  [providers.BETRADAR]: 'BetRadar:',
  [providers.LSPORTS]: 'LSports:',
};

const labelsByFeedStatus = {
  [feedStatus.UNKNOWN]: 'Data error',
  [feedStatus.LOW]: 'Low quality',
  [feedStatus.MEDIUM]: 'Medium quality',
  [feedStatus.HIGH]: 'High quality',
};

const classesByFeedStatus = {
  [feedStatus.UNKNOWN]: 'error',
  [feedStatus.LOW]: 'low',
  [feedStatus.MEDIUM]: 'medium',
  [feedStatus.HIGH]: 'high',
};

export default {
  components: {
    XIcon,
    Icon,
    Button,
    ReplayManagerSchedule,
    Tooltip,
  },
  props: {
    eventId: {
      type: String,
      required: true,
    },
    eventName: {
      type: String,
      required: true,
    },
    eventType: {
      type: String,
      default: '',
    },
    isUsaView: {
      type: Boolean,
      required: true,
    },
  },
  emits: {
    [emitTypes.CLOSE]: {},
  },
  setup(props, { emit }) {
    const store = useStore();
    const eventHealth = ref({});

    const state = reactive({
      loading: false,
      processing: false,
      available: false,
      schedules: [
        0,
      ],
    });
    const title = computed(() => {
      if (!state.available) return 'Unable to start replay';
      return 'Schedule event replay';
    });
    const subtitle = computed(() => {
      if (!props.isUsaView) return props.eventName;
      return props.eventName.split(' v ').reverse().join(' @ ');
    });

    const getHealthStatus = (feedSize) => {
      const status = feedSize || feedStatus.UNKNOWN;
      return {
        label: labelsByFeedStatus[status],
        className: classesByFeedStatus[status],
      };
    };

    const checkAvailability = async () => {
      try {
        state.loading = true;
        state.available = await isReplayable(props);
        eventHealth.value = await recordingInfo(props);
      } catch (error) {
        console.error(error);
        state.available = false;
      } finally {
        state.loading = false;
      }
    };
    const close = () => {
      if (state.loading || state.processing) return;
      emit(emitTypes.CLOSE);
    };

    const replayManagerRef = ref(null);

    onClickOutside(replayManagerRef, () => {
      close();
    });

    const confirm = async () => {
      try {
        state.processing = true;
        const offsets = state.schedules.map((offset) => offset * -1);
        const success = await scheduleReplay({ ...props, offsets });

        if (!success) throw new Error('Event replay failed');

        store.dispatch('addNotification', {
          message: 'Replay has been scheduled successfully',
          type: 'success',
          duration: 5000,
        });

        state.processing = false;
        close(true);
      } catch (error) {
        state.processing = false;
        store.dispatch('addNotification', {
          message: 'Replay scheduling failed. Try again later.',
          type: 'error',
          duration: 5000,
        });
      }
    };

    onMounted(checkAvailability);

    return {
      state,
      title,
      subtitle,
      confirm,
      close,
      replayManagerRef,
      eventHealth,
      getHealthStatus,
      providers,
      providerNames,
    };
  },
};
</script>

<style lang="scss" scoped>
.replay-manager {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9000;

  &__loader {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 44px;
    padding: 0 12px;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: 400;
    user-select: none;
  }
}

.replay-manager__dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: #F0F0F0;
  border-radius: 4px;
  overflow: hidden;
}

.replay-manager__header,
.replay-manager__footer {
  padding: 1rem;
}

.replay-manager__header {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 33px;
  grid-template-rows: 1fr auto;
  row-gap: 8px;
}

.replay-manager__title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.replay-manager__close-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px;
  gap: 4px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: #fff;
  stroke: #191414;

  &:hover {
    background-color: #FAFAFA;
  }

  &:active {
    background-color: #F0F0F0;
  }
}

.replay-manager__subtitle {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #A9A9A9;
}

.replay-manager__content {
  width: 360px;
  // max-height: 460px;
  // overflow-y: auto;
}

.replay-manager__message {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.replay-manager__form {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 1rem;
  border-top: 1px solid #F0F0F0;
  max-height: 300px;
  overflow-y: auto;
}

.replay-manager__form-title {
  background: inherit;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #A9A9A9;
}

.replay-manager__form-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.replay-manager__form-schedule {
  display: flex;
  align-items: center;
  gap: 8px;
}

.replay-manager__form-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px;
  gap: 8px;
  width: 44px;
  height: 33px;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  text-align: center;
}

.replay-manager__footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #F0F0F0;
}

.replay-manager__replay-health {
  padding: 1rem;
  border-top: 1px solid #F0F0F0;
}

.replay-manager__replay-health-header {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: #A9A9A9;
  }

.replay-manager__replay-health-badge {
  display: inline-flex;
  align-items: center;
  background-color: #FFC531;
  border: $buttonBorder;
  border-radius: $buttonBorderRadius;
  padding: 2px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #000000;
  text-transform: initial;

  &.low {
    background-color: #FF2E2D;
  }
  &.medium {
    background-color: #FFC531;
  }
  &.high {
    background-color: #00BC57;
  }
  &.error {
    background-color: #DDDDDD;
  }
}

.replay-manager__replay-providers {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  text-transform: initial;
  color: black;
  gap: 0 16px;
  margin-top: 8px;

  > div {
    display: flex;
    gap: 0 4px;
    align-items: center;
  }

}

</style>
<style lang="scss">
.replay-manager__replay-providers {
  .icon {
    &.error {
      path,
      circle {
        fill: #DDDDDD;
      }
    }
    &.low {
      path,
      circle {
        fill: #FF2E2D;
      }
    }
    &.medium {
      path,
      circle {
        fill: #FFC531;
      }
    }
    &.high {
      path,
      circle {
        fill: #00BC57;
      }
    }
  }
}
</style>
