<template>
  <div
    class="scoreboard-player-statistics-team-player-header"
    @click="() => { playerToggled = !playerToggled; }"
  >
    <Icon :name="playerToggled ? 'chevron-up': 'chevron-down'" />
    {{ player.playerName }}
  </div>
  <component
    v-if="showDetails"
    :is="detailsComponent"
    :player="player"
    :event="event"
  />
</template>

<script>
import { ref, computed } from 'vue';
import Icon from '@/components/common/Icon';
import ScoreboardPlayerStatisticsFootballDetails from './ScoreboardPlayerStatisticsFootballDetails';
import ScoreboardPlayerStatisticsBasketballDetails from './ScoreboardPlayerStatisticsBasketballDetails';
import ScoreboardPlayerStatisticsBaseballDetails from './ScoreboardPlayerStatisticsBaseballDetails';

export default {
  components: {
    Icon,
    ScoreboardPlayerStatisticsFootballDetails,
    ScoreboardPlayerStatisticsBasketballDetails,
    ScoreboardPlayerStatisticsBaseballDetails,
  },
  props: {
    player: {
      type: Object,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const playerToggled = ref(true);
    const detailsComponent = computed(() => {
      switch (props.event.sportId) {
      case '841e188b-0dcf-4f5f-974f-aa52c8cec95b':
        return 'ScoreboardPlayerStatisticsFootballDetails';
      case 'cf64a1fd-9982-48f7-a2e4-0897cc8c668f':
        return 'ScoreboardPlayerStatisticsBasketballDetails';
      case '4e8eca10-6afa-44ed-af77-42414ec45cb3':
        return 'ScoreboardPlayerStatisticsBaseballDetails';
      default:
        return '';
      }
    });
    const showDetails = computed(() => playerToggled.value && detailsComponent.value);

    return {
      playerToggled,
      detailsComponent,
      showDetails,
    };
  },
};
</script>

<style lang="scss">
  .scoreboard-player-statistics-team-player-header {
    width: 100%;
    display: flex;
    align-items: center;
    height: 40px;
    background-color: #FAFAFA;
    border-radius: 4px;
    padding: 0 8px;
    margin-bottom: 20px;
    cursor: pointer;
    font-weight: 600;

    .icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;

      svg {
        width: 16px;
        height: 16px;
        stroke: #191414;

        path {
          stroke-width: 1;
        }
      }
    }
  }
</style>
