<template>
  <div class="scoreboard-player-statistics">
    <div class="scoreboard-player-statistics-header-wrapper">
      <div class="scoreboard-player-statistics-header-label-search">
        <div class="scoreboard-player-statistics-header-label">
          Player statistics
        </div>
        <div class="scoreboard-player-statistics-header-search">
          <TextInput
            :model-value="searchValue"
            :placeholder="'Search players'"
            :icon="'search'"
            @update:modelValue="onSearch"
          />
        </div>
      </div>
      <div class="scoreboard-player-statistics-header-team-selection">
        <Dropdown :label="appliedTeamFilter.label">
          <DropdownItem
            v-for="team in teamFilterData"
            :key="team.team"
            clickable
            @click="applyTeamFilter(team)"
          >
            {{ team.label }}
          </DropdownItem>
        </Dropdown>
      </div>
      <div
        v-if="isBaseball"
        class="scoreboard-baseball-navigation"
      >
        <div
          :class="['scoreboard-baseball-navigation-tab', { 'selected': selectedPlayerType === 'pitchers' }]"
          @click="selectPlayerType('pitchers')"
        >
          Pitchers
        </div>
        <div
          :class="['scoreboard-baseball-navigation-tab', { 'selected': selectedPlayerType === 'hitters' }]"
          @click="selectPlayerType('hitters')"
        >
          Hitters
        </div>
      </div>
    </div>
    <div class="scoreboard-player-statistics-body">
      <div
        class="scoreboard-player-statistics-team"
        v-if="appliedTeamFilter.team === -1 || appliedTeamFilter.team === 0"
      >
        <div class="scoreboard-player-statistics-team-label">
          {{ event.away.name }}
        </div>
        <div
          class="scoreboard-player-statistics-team-player"
          v-for="player in awayTeamPlayers"
          :key="player.playerId"
        >
          <ScoreboardPlayerStatisticsDetails
            :player="player"
            :event="event"
          />
        </div>
        <div v-if="!awayTeamPlayers.length">
          No players found.
        </div>
      </div>
      <div
        class="scoreboard-player-statistics-team"
        v-if="appliedTeamFilter.team === -1 || appliedTeamFilter.team === 1"
      >
        <div class="scoreboard-player-statistics-team-label">
          {{ event.home.name }}
        </div>
        <div
          class="scoreboard-player-statistics-team-player"
          v-for="player in homeTeamPlayers"
          :key="player.playerId"
        >
          <ScoreboardPlayerStatisticsDetails
            :player="player"
            :event="event"
          />
        </div>
        <div v-if="!homeTeamPlayers.length">
          No players found.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, provide } from 'vue';
import { useStore } from 'vuex';
import {
  map, find, orderBy,
  trim, includes, toLower, filter,
} from 'lodash';
import sportIds from '@/services/helpers/sports';
import TextInput from '@/components/common/TextInput';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';
import ScoreboardPlayerStatisticsDetails from './ScoreboardPlayerStatisticsDetails';

const { FOOTBALL_ID, BASEBALL_ID } = sportIds;

const FOOTBALL_PLAYER_POSITIONS = ['OFFENSIVE_NON_QB', 'QUARTERBACK', 'UNKNOWN'];

export default {
  components: {
    TextInput,
    Dropdown,
    DropdownItem,
    ScoreboardPlayerStatisticsDetails,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const eventTeamPlayers = computed(() => store.getters.eventTeamPlayers);

    const searchValue = ref('');
    const selectedPlayerType = ref('pitchers');
    provide('selectedPlayerType', selectedPlayerType);
    const isBaseball = computed(() => props.event.sportId === BASEBALL_ID);

    const onSearch = (value) => {
      searchValue.value = value;
    };
    const selectPlayerType = (playerType) => {
      selectedPlayerType.value = playerType;
    };

    const homeLineup = computed(() => {
      let lineup = props.event.homeLineup ?? [];
      if (props.event.sportId === FOOTBALL_ID) lineup = filter(lineup, ({ position }) => includes(FOOTBALL_PLAYER_POSITIONS, position));
      return lineup;
    });
    const awayLineup = computed(() => {
      let lineup = props.event.awayLineup ?? [];
      if (props.event.sportId === FOOTBALL_ID) lineup = filter(lineup, ({ position }) => includes(FOOTBALL_PLAYER_POSITIONS, position));
      return lineup;
    });

    const applyPlayerSearchFilter = (player) => includes(toLower(player.playerName), toLower(searchValue.value));
    const applyPlayerTypeFilter = (player) => {
      switch (selectedPlayerType.value) {
      case 'pitchers':
        return !!player.playerStats.pitcherDetails;
      case 'hitters':
        return !!player.playerStats.batterDetails;
      default:
        return false;
      }
    };
    const applyPlayerFilters = (player) => {
      if (!isBaseball.value) return applyPlayerSearchFilter(player);
      return applyPlayerTypeFilter(player) && applyPlayerSearchFilter(player);
    };

    const homeTeamPlayers = computed(() => orderBy(filter(map(homeLineup.value, (player) => {
      const foundPlayer = find(eventTeamPlayers.value?.home, (squadPlayer) => squadPlayer.playerId === player.playerId);
      const playerName = foundPlayer?.personalInfo?.name;
      const mappedPlayerName = playerName ? `${trim(playerName.split(',')[1])} ${playerName.split(',')[0]}` : 'N/A';
      return {
        ...player,
        playerName: mappedPlayerName,
      };
    }), applyPlayerFilters), 'playerName'));

    const awayTeamPlayers = computed(() => orderBy(filter(map(awayLineup.value, (player) => {
      const foundPlayer = find(eventTeamPlayers.value?.away, (squadPlayer) => squadPlayer.playerId === player.playerId);
      const playerName = foundPlayer?.personalInfo?.name;
      const mappedPlayerName = playerName ? `${trim(playerName.split(',')[1])} ${playerName.split(',')[0]}` : 'N/A';
      return {
        ...player,
        playerName: mappedPlayerName,
      };
    }), applyPlayerFilters), 'playerName'));

    const teamFilterData = computed(() => [
      {
        team: -1,
        label: 'Both Teams',
      },
      {
        team: 0,
        label: props.event.away.name,
      },
      {
        team: 1,
        label: props.event.home.name,
      },
    ]);

    const appliedTeamFilter = ref(teamFilterData.value[0]);

    const applyTeamFilter = (team) => { appliedTeamFilter.value = team; };

    return {
      homeTeamPlayers,
      awayTeamPlayers,
      teamFilterData,
      appliedTeamFilter,
      applyTeamFilter,
      searchValue,
      onSearch,
      selectedPlayerType,
      selectPlayerType,
      isBaseball,
    };
  },
};
</script>

<style lang="scss">
.scoreboard-player-statistics {
  width: 100%;

  .scoreboard-player-statistics-header-wrapper {
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid #F0F0F0;

    .scoreboard-player-statistics-header-label-search {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 33px;
      margin-bottom: 16px;

      .scoreboard-player-statistics-header-label {
        height: 100%;
        line-height: 33px;
        font-weight: 600;
      }

      .scoreboard-player-statistics-header-search {
        width: 230px;

        .text-field {
          height: 33px;

          .text-field-clear {
            width: 33px;
            height: 33px;
          }
        }
      }
    }
  }

  .scoreboard-player-statistics-body {
    width: 100%;
    overflow-y: auto;
    max-height: calc(100vh - 563px);

    .scoreboard-player-statistics-team {
      width: 100%;
      padding: 16px 0;

      .scoreboard-player-statistics-team-label {
        font-weight: 600;
        margin-bottom: 16px;
      }

      .scoreboard-player-statistics-team-player {
        width: 100%;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .scoreboard-baseball-navigation {
    height: 33px;
    width: 100%;
    box-shadow: inset 0px -2px 0px #CDCDCD;
    display: flex;
    margin-top: 16px;
    margin-bottom: -16px;

    .scoreboard-baseball-navigation-tab {
      height: 100%;
      padding: 0 8px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &.selected {
        box-shadow: inset 0px -2px 0px #003C3C;
      }
    }
  }
}
</style>
