<template>
  <div
    :class="[
      'scoreboard-details-periods',
      { 'scoreboard-details-periods--readonly': readonly },
    ]"
  >
    <div
      v-show="hasPrevious"
      class="scoreboard-details-periods__action scoreboard-details-periods__action--previous"
    >
      <span class="scoreboard-details-periods__action-shadow" />

      <div
        class="scoreboard-details-periods__action-tab"
        @click="previous"
      >
        <Icon name="chevron-left" />
      </div>
    </div>

    <ul
      class="scoreboard-details-periods__content"
      :style="{
        gap: `${itemGap}px`,
      }"
    >
      <li
        v-for="tab in periods"
        :key="tab.code"
        :class="[
          'scoreboard-details-periods__item',
          { 'scoreboard-details-periods__item--active': selected === tab.code },
        ]"
        :style="{
          width: `${itemWidth}px`,
          'min-width': `${itemWidth}px`,
          transform: `translate(${-1 * (itemWidth + itemGap) * scrollPosition}px)`,
        }"
        @click="select(tab)"
      >
        {{ tab.label }}
        <span v-if="isUsaView">{{ tab.awayValue }} - {{ tab.homeValue }}</span>
        <span v-else>{{ tab.homeValue }} - {{ tab.awayValue }}</span>
      </li>
    </ul>

    <div
      v-show="hasNext"
      class="scoreboard-details-periods__action scoreboard-details-periods__action--next"
    >
      <span class="scoreboard-details-periods__action-shadow" />

      <div
        class="scoreboard-details-periods__action-tab"
        @click="next"
      >
        <Icon name="chevron-right" />
      </div>
    </div>
  </div>
</template>

<script>
import { min } from 'lodash';
import { ref, computed } from 'vue';
import Icon from '@/components/common/Icon';

const emitTypes = {
  UPDATE_SELECTED: 'update:selected',
};

export default {
  components: {
    Icon,
  },
  props: {
    selected: {
      type: String,
      required: true,
    },
    periods: {
      type: Array,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    isUsaView: {
      type: Boolean,
    },
  },
  emits: {
    [emitTypes.UPDATE_SELECTED]: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const itemWidth = ref(109);
    const itemGap = ref(8);
    const visibleItemCount = computed(() => min([props.periods.length, 5]));
    const scrollTicks = computed(() => props.periods.length - visibleItemCount.value);

    const scrollPosition = ref(0);
    const hasPrevious = computed(() => scrollTicks.value > 0 && (scrollPosition.value - 1 >= 0));
    const previous = () => {
      if (hasPrevious.value) scrollPosition.value -= 1;
    };
    const hasNext = computed(() => scrollTicks.value > 0 && (scrollPosition.value + 1 <= scrollTicks.value));
    const next = () => {
      if (hasNext.value) scrollPosition.value += 1;
    };

    const select = ({ code }) => {
      if (props.readonly) return;
      emit(emitTypes.UPDATE_SELECTED, code);
    };

    return {
      itemWidth,
      itemGap,
      scrollPosition,
      hasPrevious,
      previous,
      hasNext,
      next,
      select,
    };
  },
};
</script>

<style lang="scss">
.scoreboard-details-periods {
  display: flex;
  align-items: center;
  position: relative;
  height: 66px;
  margin: 0 0 20px;

  .scoreboard-details-periods__action {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 100;
    top: 0;
    width: 92px;
    height: 100%;

    &.scoreboard-details-periods__action--previous {
      left: 0;
      justify-content: flex-start;
    }

    &.scoreboard-details-periods__action--next {
      right: 0;
      justify-content: flex-end;
    }
  }

  .scoreboard-details-periods__action-tab {
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    stroke: #000;
    box-sizing: border-box;
    border-radius: 4px;
    width: 33px;
    height: 100%;
    cursor: pointer;
    z-index: 100;
    position: relative;
    padding: 8px;
  }

  .scoreboard-details-periods__action-shadow {
    content: '';
    width: 92px;
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: 0;
  }

  .scoreboard-details-periods__action {
    &.scoreboard-details-periods__action--previous {
      .scoreboard-details-periods__action-shadow {
        background: linear-gradient(270deg, rgba(255, 255, 255, 0) -5.43%, #FFFFFF 50.22%);
      }
    }

    &.scoreboard-details-periods__action--next {
      .scoreboard-details-periods__action-shadow {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) -5.43%, #FFFFFF 50.22%);
      }
    }
  }

  .scoreboard-details-periods__content {
    flex: 1;
    display: flex;
    overflow: hidden;
  }

  .scoreboard-details-periods__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #FAFAFA;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    border-radius: 4px;
    height: 66px;
    color: #6D6D6D;
    font-size: 14px;
    gap: 7px;
    cursor: pointer;
    transition: transform 0.25s;

    span {
      color: #000;
    }

    &.scoreboard-details-periods__item--active {
      background-color: #003C3C;
      color: #fff;

      span {
        color: #fff;
      }
    }
  }

  &.scoreboard-details-periods--readonly {
    .scoreboard-details-periods__item,
    .scoreboard-details-periods__action-tab {
      cursor: default;
    }
  }
}
</style>
